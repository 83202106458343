<template>
  <section :id="$route.name">
    <AboutCards
      :appThemeColor="appThemeColor"
      :materi="materi"
      v-for="materi in pertemuan"
      :key="materi.id"
    />
  </section>
</template>

<script>
import AboutCards from "@/components/AboutCards.vue";

export default {
  components: {
    AboutCards,
  },
  props: ["appThemeColor"],
  data: () => ({
    pertemuan: [
      {
        id: 15,
        kuliah: [
          {
            judul: "CRUD Apps",
            bahasan: [
              "URL boleh sama, tapi dibedakan request methods-nya",
              "Create atau Update bisa dengan request yang sama",
              "Create apabila npm belum terdaftar, Update apabila npm sudah terdaftar",
            ],
          },
        ],
        kulon: "Google Meet",
        show: false,
        text: "Tampilkan",
      },
      {
        id: 14,
        kuliah: [
          {
            judul: "Vue.js & Express.js",
            bahasan: ["Vue.js untuk frontend", "Express.js untuk backend"],
          },
          {
            judul: "Axios & Vuex",
            bahasan: [
              "Axios untuk melakukan request ke server",
              "Vuex untuk state management",
            ],
          },
        ],
        kulon: "Google Meet",
        show: false,
        text: "Tampilkan",
      },
      {
        id: 13,
        kuliah: [
          {
            judul: "Vue.js",
            bahasan: [
              "Directives: <code>v-for</code>, <code>v-if</code>",
              "props",
              "vuex: <code>state</code>, <code>mutations</code>, <code>getters</code>",
            ],
          },
        ],
        kulon: "Google Meet",
        show: false,
        text: "Tampilkan",
      },
      {
        id: 12,
        kuliah: [
          {
            judul: "Exploring more vue.js features",
            bahasan: [
              "Penyesuaian wireframes",
              "Mengambil data dari <code>$router</code> untuk ditampilkan pada navigation drawer dengan <code>v-for</code>",
              "Penambahan meta tag pada base html",
              "Coba build dan upload ke cpanel",
            ],
          },
        ],
        kulon: "Belajar Mandiri",
        show: false,
        text: "Tampilkan",
      },
      {
        id: 11,
        kuliah: [
          {
            judul: "Vuetify - Komponen",
            bahasan: ["Vuetify - Komponen", "Contoh penggunaan komponen"],
          },
          {
            judul: "Vue.js",
            bahasan: ["watch", "computed"],
          },
        ],
        kulon: "Google Meet",
        show: false,
        text: "Tampilkan",
      },
      {
        id: 10,
        kuliah: [
          {
            judul: "Vue.js",
            bahasan: ["methods", "Lifecycle"],
          },
        ],
        kulon: "Google Meet",
        show: false,
        text: "Tampilkan",
      },
      {
        id: 9,
        kuliah: [
          {
            judul: "Vue.js",
            bahasan: [
              "instalasi",
              "Buat project",
              "Add Vuetify",
              "Coba modifikasi tampilan Home",
            ],
          },
        ],
        kulon: "Google Meet",
        show: false,
        text: "Tampilkan",
      },
    ],
  }),
};
</script>
