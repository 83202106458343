<template>
  <v-hover v-slot="{ hover }">
    <AhoCard1 :mTop="mTop">
      <template #default>
        <v-card
          :color="
            ipkE1 || ipkE2 ? 'error' : editing ? 'warning' : appThemeColor
          "
          dark
          class="aho-tr1 depth-2 rounded"
          :class="'mt-' + (hover ? 'n' + mTop * 1.5 : 'n' + mTop)"
          style="position: absolute; width: 100%"
        >
          <v-card-title>
            {{ editing ? "Editing: " + editNpm : "Form Data Mahasiswa" }}
          </v-card-title>
          <v-card-subtitle>
            {{
              ipkE1
                ? "IPK harus diantara 0 - 4"
                : ipkE2
                ? "IPK harus berupa angka"
                : subtitle
            }}
          </v-card-subtitle>
        </v-card>
      </template>
      <template #a>
        <v-card-subtitle> &nbsp; </v-card-subtitle>
        <v-card-text>
          <v-row v-for="(data, i) in mhs" :key="i" align="center">
            <v-col cols="3" sm="2">
              <label :for="'input' + labels[i]">
                {{ labels[i] }}
              </label>
            </v-col>
            <v-col cols="9" sm="10">
              <v-text-field
                :id="'input' + labels[i]"
                ref="input"
                v-model="mhs[i]"
                outlined
                clearable
                dense
                hide-details
                @keyup.enter="simpanMhs"
                :color="appThemeColor"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex justify-end pt-0">
          <div class="pa-2"></div>
          <transition name="fade" mode="out-in">
            <v-btn
              color="error"
              dark
              key="3"
              @click="clearMhs"
              v-if="mhsIsEmpty"
            >
              Bersihkan
            </v-btn>
          </transition>
          <div class="pa-2"></div>
          <v-btn :color="appThemeColor" dark @click="simpanMhs">
            {{ editing ? "Update" : "Simpan" }}
          </v-btn>
        </v-card-text>
      </template>
    </AhoCard1>
  </v-hover>
</template>

<script>
import { mapGetters } from "vuex";
import AhoCard1 from "@/components/AhoCard1.vue";

export default {
  name: "AhoForm",
  components: {
    AhoCard1,
  },
  props: ["mTop", "appThemeColor", "subtitle", "list"],
  data: () => ({}),
  computed: {
    ...mapGetters({
      labels: "getLabels",
      mhs: "getMahasiswa",
      listA: "getListMhs",
      listB: "getListMahasiswa",
      ipkE1: "getIpkE1",
      ipkE2: "getIpkE2",
      editing: "getEditing",
      editNpm: "getEditNpm",
    }),
    listMhs() {
      return this.list == "Mhs" ? this.listA : this.listB;
    },
    mhsIsEmpty() {
      return !Object.values(this.mhs).every((x) => x === null || x === "");
    },
  },
  watch: {
    mhs: {
      handler() {
        this.checkErr();
      },
      deep: true,
    },
  },
  methods: {
    checkIpkErr1() {
      this.$store.commit("setIpkE1", this.mhs.ipk < 0 || this.mhs.ipk > 4);
    },
    checkIpkErr2() {
      this.$store.commit("setIpkE2", isNaN(parseFloat(this.mhs.ipk)));
    },
    checkDuplikat() {
      // apabila npm sudah terdaftar => update else add
      const npm = this.mhs.npm;
      const m = this.listMhs.some((item) => {
        this.$store.commit("setEditNpm", npm);
        return item.npm === npm;
      });
      this.$store.commit("setEditing", m);
    },
    checkErr() {
      this.checkDuplikat();
      if (this.mhs.ipk == "" || this.mhs.ipk == null) {
        this.$store.commit("setIpkE1", false);
        this.$store.commit("setIpkE2", false);
      } else {
        this.checkIpkErr1();
        this.checkIpkErr2();
      }
    },
    clearMhs() {
      this.$store.commit("clearMahasiswa");
    },
    simpanMhs() {
      this.$emit("simpanMhs");
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
