<template>
  <v-app
    id="aho"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <v-navigation-drawer
      :color="appThemeColor + ' lighten-5'"
      app
      floating
      v-model="drawer"
      :style="'padding-top:' + $vuetify.application.top + 'px;'"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">{{
            authorName
          }}</v-list-item-title>
          <v-list-item-subtitle> NPM: {{ authorNPM }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav shaped>
        <v-list-item
          v-for="route in $router.options.routes"
          :key="route.path"
          link
          :to="route.path"
          :color="appThemeColor"
        >
          <v-list-item-icon>
            <v-icon>{{ route.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ route.meta.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-select
              v-model="appThemeColor"
              :items="appThemeColors"
              :color="appThemeColor"
              label="Ganti tema"
              outlined
              dense
              hide-details
            ></v-select>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card
        class="elevation-0 mx-4"
        :style="'margin-bottom:' + ($vuetify.application.top + 16) + 'px;'"
      >
        <v-card-text> {{ $route.meta.description }} </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-app-bar
      id="app-bar"
      app
      :color="appThemeColor"
      dark
      :class="clsABr + ($vuetify.breakpoint.mobile ? mbl : big)"
      clipped-left
      :style="{ zIndex: 6 }"
    >
      <v-img
        max-width="36"
        :src="require('./assets/utama.svg')"
        contain
        @click.stop="drawer = !drawer"
        style="cursor: pointer"
      ></v-img>
      <v-toolbar-title class="d-flex flex-column pl-5">
        <div class="mb-n1">Web Programming</div>
        <span class="caption font-weight-light">
          Dosen: Esa Fauzi, S.T., M.T.
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <span class="pl-5 d-none d-sm-block">Universitas Widyatama</span>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container fluid :class="clsCon" class="flex-grow-1" tag="section">
        <h1
          class="text-h4 text-sm-h3 mt-2 mt-sm-0 pb-6 font-weight-light"
          :class="appThemeColor + '--text'"
          id="pageTitle"
        >
          {{ $route.meta.title }}
        </h1>
        <div id="rvWrap">
          <router-view :appThemeColor="appThemeColor" />
        </div>
        <v-btn
          :color="appThemeColor"
          fab
          :class="clsFab"
          fixed
          style="bottom: 0; right: 0; z-index: 6"
          @click.stop="drawer = !drawer"
        >
          <svg id="myBar" viewBox="0 0 510 510" :class="drawer ? 'cross' : ''">
            <path
              d="M135,150 C135,150 355,150 375,150 C575,150 475,470 355,350 C275,270 135,130 135,130"
              class="x"
            />
            <path d="M135,255 L375,255" class="y" />
            <path
              d="M135,360 C135,360 355,360 375,360 C575,360 475,40  355,160 C275,240 135,380 135,380"
              class="z"
            />
          </svg>
        </v-btn>
        <v-snackbar
          v-model="snackbar"
          :snackBarTimeout="snackBarTimeout"
          :color="snackBarColor"
        >
          {{ snackBarText }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
      <v-footer padless>
        <v-col class="py-0" :class="clsABr" cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col
          class="text-center"
          cols="12"
          :class="clsCon"
          style="line-height: 1"
        >
          <div>{{ new Date().getFullYear() }} — <strong>UTama</strong></div>
          <div class="pt-4">v{{ appVersion }}</div>
        </v-col>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { version } from "../package";

export default {
  data: () => ({
    appVersion: version,
    authorNPM: process.env.VUE_APP_AUTHOR_NPM,
    authorName: process.env.VUE_APP_AUTHOR_NAME,
    drawer: null,
    clsFab: "ma-4 ma-sm-6 ma-md-8",
    clsCon: "pa-4 pa-sm-6 pa-md-8",
    clsABr: "px-4 px-sm-6 px-md-8 ",
    mbl: "no-pad",
    big: "pl-lg-0",
    snackbar: false,
    snackBarTimeout: 2000,
    snackBarText: "",
    snackBarColor: "",
    appThemeColor: process.env.VUE_APP_THEME_COLOR_NAME,
    appThemeHex:
      // untuk memilih warna theme
      {
        purple: "#9C27B0",
        "deep-purple": "#673AB7",
        indigo: "#3F51B5",
        blue: "#2196F3",
        "light-blue": "#03A9F4",
        cyan: "#00BCD4",
        teal: "#009688",
        green: "#4CAF50",
        brown: "#795548",
      },
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    appThemeColors() {
      let ret = [];
      for (let key in this.appThemeHex) {
        ret.push(key);
      }
      return ret;
    },
    ...mapGetters({
      message: "getMessage", // 0 = success; 1 = error; 2 = warning; 3 = info
    }),
  },
  watch: {
    message: function (val) {
      if (val) {
        let color;
        if (val[1] == 0) {
          color = "success";
        } else if (val[1] == 1) {
          color = "error";
        }
        this.snackbar = false;
        this.snackBarText = val[0];
        this.snackBarColor = color;
        this.snackbar = true;
      }
    },
    drawer: function (val) {
      if (this.$vuetify.breakpoint.mobile) {
        if (val) {
          this.mbl = "pl-0 pl-sm-0 pl-md-0";
        } else {
          this.mbl = "no-pad";
        }
      } else {
        if (val) {
          this.big = "pl-lg-0";
        } else {
          this.big = "no-pad";
        }
      }
    },
    appThemeColor: function (val) {
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", this.appThemeHex[val]);
    },
    $route(to, from) {
      if (from.path == "/mahasiswa" || from.path == "/mhs") {
        this.$store.commit("clearMahasiswa");
        this.$store.commit("setEditing", false);
        this.$store.commit("setIpkE1", false);
        this.$store.commit("setIpkE2", false);
        this.$store.commit("setEditNpm", null);
      }
    },
  },
};
</script>

<style lang="scss">
#app-bar,
.v-app-bar .v-toolbar__content {
  transition: padding 0.3s;
}
.v-app-bar .v-toolbar__content {
  padding-right: 0px;
}
.no-pad .v-toolbar__content {
  padding-left: 0px;
}
.text-start.sortable,
.v-data-table td.text-start {
  white-space: nowrap;
}
.v-main__wrap {
  display: flex;
  flex-direction: column;
}

.op0 {
  opacity: 0;
}

#aho {
  .depth-1 {
    box-shadow: 0 1px 8px 0 rgb(0, 0, 0, 0.05);
  }
  .depth-2 {
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
      0 7px 10px -5px rgb(72 62 156 / 40%);
  }
  .aho-tr1 {
    transition: 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
  }
  .theme--light.v-data-table tbody tr.v-data-table__selected {
    background: #ffe0b2;
  }
}

#rvWrap {
  transform-origin: top;
}

#myBar {
  width: 40px;
  height: auto;
  transform: translate3d(0, 0, 0);
  path {
    fill: none;
    stroke-width: 40;
    stroke-linecap: round;
    stroke: #fff;
    stroke-dashoffset: 0px;
    &.x,
    &.z {
      transition: 0.5s cubic-bezier(0.45, 0.45, 0.37, 1.3);
      stroke-dasharray: 240px 950px;
    }
    &.y {
      transition: 0.3s ease;
      stroke-dasharray: 240px 240px;
    }
  }
  &.cross {
    path.x,
    path.z {
      stroke-dashoffset: -650px;
    }
    path.y {
      stroke-dashoffset: -115px;
      stroke-dasharray: 1px 220px;
    }
  }
}
</style>
