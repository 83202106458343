<template>
  <v-row>
    <v-col cols="12" lg="7">
      <AhoForm
        :mTop="mTop"
        :appThemeColor="appThemeColor"
        :subtitle="'Data akan disimpan di REST server ' + devMode"
        @simpanMhs="simpanMhs"
        ref="form"
        list="Mhs"
      />
    </v-col>
    <v-col cols="12" lg="5">
      <AhoSimpleTable :mTop="mTop" :appThemeColor="appThemeColor" />
    </v-col>
    <v-col cols="12">
      <AhoDataTable
        :mTop="mTop"
        :appThemeColor="appThemeColor"
        list="Mhs"
        :loading="loading"
        @focusInput="focusInput"
        @deleteCnf="deleteCnf"
      >
        <template>
          <v-toolbar-title>Data di REST server {{ devMode }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="getMhs">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
      </AhoDataTable>
    </v-col>
    <AhoDialog
      :btnCol="[`${appThemeColor}`, 'error']"
      :modelValue="dialog"
      @update:modelValue="(newValue) => (dialog = newValue)"
      @batal="dialog = false"
      @lanjut="deleteMhs(deleteConfirm)"
    >
      <template #title>Hapus data</template>
      <template>
        Apakah anda yakin ingin menghapus NPM: <kbd>{{ deleteConfirm }}</kbd> ?
      </template>
    </AhoDialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import AhoForm from "@/components/AhoForm.vue";
import AhoSimpleTable from "@/components/AhoSimpleTable.vue";
import AhoDataTable from "@/components/AhoDataTable.vue";
import AhoDialog from "@/components/AhoDialog.vue";

export default {
  name: "ServerMahasiswa",
  components: {
    AhoForm,
    AhoSimpleTable,
    AhoDataTable,
    AhoDialog,
  },
  props: ["appThemeColor"],
  data: () => ({
    mTop: 4,
    loading: true,
    dialog: false,
    deleteConfirm: null,
    gettingData: true,
  }),
  computed: {
    devMode() {
      return process.env.VUE_APP_MODE == "development" ? "express" : "php";
    },
    ...mapGetters({
      labels: "getLabels",
      headers: "getHeaders",
      mhs: "getMahasiswa",
      listMhs: "getListMhs",
      message: "getMessage", // 0 = success; 1 = error; 2 = warning; 3 = info
      ipkE1: "getIpkE1",
      ipkE2: "getIpkE2",
    }),
  },
  watch: {
    dialog: function (val) {
      if (!val && this.deleteConfirm != null) {
        this.deleteConfirm = null;
      }
    },
    message: function (val) {
      if (val) {
        if (this.gettingData) {
          this.gettingData = false;
          this.done();
        }
      }
    },
  },
  methods: {
    focusInput(n) {
      this.$refs.form.$refs.input[n].focus();
    },
    bukaSnackBar(txt, input, color) {
      this.$store.commit("setMessage", [txt, color]);
      if (input >= 0) {
        this.focusInput(input);
      }
    },
    async simpanMhs() {
      let itr = 0;
      for (const [key, value] of Object.entries(this.mhs)) {
        if (value == "" || value == null) {
          this.bukaSnackBar(`${this.labels[key]} tidak boleh kosong`, itr, 1);
          return;
        }
        if (key == "ipk") {
          if (this.ipkE1) {
            this.bukaSnackBar(
              `${this.labels[key]} harus diantara 0 - 4`,
              itr,
              1
            );
            return;
          }
          if (this.ipkE2) {
            this.bukaSnackBar(`${this.labels[key]} harus berupa angka`, itr, 1);
            return;
          }
        }
        itr++;
      }
      let mhs = {
        npm: this.mhs.npm,
        nama: this.mhs.nama,
        alamat: this.mhs.alamat,
        ipk: parseFloat(this.mhs.ipk).toFixed(1),
      };
      this.loading = true;
      await this.$store.dispatch("putMhs", mhs);
      if (this.message[1] == 0) {
        this.$store.commit("clearMahasiswa");
      }
      this.done();
    },
    async deleteMhs(npm) {
      this.loading = true;
      await this.$store.dispatch("deleteMhs", npm);
      if (this.message[1] == 0) {
        this.dialog = false;
        this.deleteConfirm = null;
      }
      this.done();
    },
    deleteCnf(npm) {
      this.deleteConfirm = npm;
      this.dialog = true;
    },
    async getMhs() {
      this.loading = true;
      await this.$store.dispatch("getDataMhs");
      this.done();
    },
    done() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  beforeCreate() {
    this.$store.commit("setServer", process.env.VUE_APP_SERVER);
    this.$store.dispatch("getDataMhs");
  },
};
</script>
