<template>
  <div id="UwCon" class="pb-4">
    <svg
      id="logoUTama"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16933 3429"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
    >
      <defs>
        <clipPath id="p01">
          <path
            d="M951 1881c116 418 436 1143 1582 1186 543 20 1124-264 1477-608 407-397 639-935 671-1445 84 599-154 1252-465 1611-350 404-937 801-1691 801S951 2867 951 1881z"
          />
        </clipPath>
        <clipPath id="p02">
          <path
            d="M3554 2182s-636 609-1554 382c-591-146-900-662-810-1211 99-597 640-1227 1440-1331 851-111 1394 302 1394 865 0 635-519 1107-898 1107s-312-338-299-395 129-516 129-516l-808 839c-41 43-95 14-67-57s230-546 317-903c34-138 17-260-89-322-98-57-307-9-467 147 62-91 370-344 604-176 153 110 140 283 51 565-55 175-119 377-123 389-4 11 8 32 30 12 21-20 861-904 861-904s-245 963-263 1051 17 164 172 166c155 3 437-211 541-507 105-296 115-710-247-997-361-287-829-308-1172-182s-724 432-865 842c-129 377 60 952 584 1228s1251 87 1540-91z"
          />
        </clipPath>
      </defs>
      <path
        class="l-clip"
        id="logoLine"
        fill="none"
        style="clip-path: url(#p01)"
        d="M1078 1181c-309 513-173 2079 1511 2066S5103 1275 4456 166"
      />
      <path
        class="l-clip"
        id="logoN"
        fill="none"
        style="clip-path: url(#p02)"
        d="M1617 1098c101-205 389-589 667-471 271 115-10 688-156 1118-160 474-268 240-63 66S3146 590 3227 524s-42-131-85 69-380 1340-186 1399c160 48 341 29 545-125 220-167 420-599 393-994-51-760-1105-970-1739-670s-1137 1062-692 1793 1836 679 2507-211"
      />
      <g id="logoText">
        <path
          d="M5307 2746h228v33c-13 1-43 3-51 13-5 7-7 26-7 58v233c0 64 9 110 27 137s49 41 93 41c47 0 80-14 101-43s32-75 32-138v-230c0-32-2-52-7-58-8-10-38-12-51-13v-33h168v33c-12 1-43 3-50 13-5 7-7 26-7 58v221c0 77-16 134-49 170-33 37-83 55-152 55-145 0-218-73-218-218v-225c0-33-2-52-7-59-8-11-38-12-51-13v-36z"
        />
        <path
          d="M5923 2746h134l296 406v-301c0-32-2-52-7-58-7-10-38-12-51-13v-33h169v33c-13 1-43 3-51 13-5 7-7 26-7 58v440h-74l-299-408v295c0 32 2 52 7 59 8 11 38 12 51 13v33h-169v-33c13-1 43-2 51-13 5-7 7-26 7-59v-328c0-32-2-52-7-58-8-10-38-12-51-13v-33z"
        />
        <path
          d="M6545 2746h228v33c-13 1-44 3-51 13-5 7-7 26-7 58v328c0 32 2 52 7 59 8 11 38 12 51 13v33h-228v-33c13-1 43-2 51-13 5-7 7-26 7-59v-328c0-32-2-52-7-58-8-10-38-12-51-13v-33z"
        />
        <path
          d="M6840 2746h233v33c-12 1-23 1-35 1-9 0-14 5-14 15 0 7 6 26 19 55l121 283 116-283c2-6 6-17 11-31s7-23 7-26c0-9-7-13-22-13l-27-1v-33h152v33c-7 0-15 1-22 1-12 1-20 4-25 9-5 6-14 26-29 60l-182 440h-37l-190-440c-17-39-27-60-30-63-5-5-36-7-45-7v-33z"
        />
        <path
          d="M7437 2746h394v97h-54c-1-9-2-20-2-29 0-14-3-22-10-26-6-4-21-6-44-6h-115v204h75c29 0 45-1 49-2 7-2 7-32 8-40h38v116h-38c-1-10-1-35-11-39-6-2-22-4-46-4h-77v162c0 40 5 62 14 64 7 2 26 3 58 3h45c36 0 56-2 61-5 7-6 9-46 10-57h54v100h-410v-33c13-1 43-2 51-13 5-7 7-26 7-59v-328c0-32-2-52-7-58-8-10-38-12-51-13v-33z"
        />
        <path
          d="M7927 2746c75 0 148 0 223-1 99-2 200 10 200 135 0 74-35 125-104 153l105 147c26 35 42 55 49 61 11 8 34 9 48 10v33h-160l-149-236h-46v131c0 32 2 52 6 59 7 11 31 12 43 13v33h-215v-33c13-1 43-2 51-13 5-7 7-26 7-59v-328c0-32-2-52-7-58-8-10-38-12-51-13v-33zm166 36v231h20c77 0 123-50 123-126 0-70-34-105-102-105h-40z"
        />
        <path
          d="M8836 2756v109h-54c-1-18-3-57-15-70-18-18-41-27-68-27-48 0-83 39-83 86 0 60 67 86 112 112 69 40 138 80 138 169 0 49-18 88-55 118-37 29-86 44-148 44-44 0-94-7-151-20v-112h54c1 20 3 56 19 70 22 18 48 27 78 27 57 0 96-38 96-96 0-70-58-90-112-119-90-49-135-104-135-162 0-46 16-83 49-110 32-28 76-42 129-42 42 0 90 8 145 24z"
        />
        <path
          d="M8948 2746h228v33c-13 1-44 3-51 13-5 7-7 26-7 58v328c0 32 2 52 7 59 8 11 38 12 51 13v33h-228v-33c13-1 43-2 51-13 5-7 7-26 7-59v-328c0-32-2-52-7-58-8-10-38-12-51-13v-33z"
        />
        <path
          d="M9221 2746h502v112h-54c-1-16 0-57-10-67-7-6-21-9-44-9h-87v397c2 36 4 57 8 61 6 7 47 10 59 11v33h-246v-33c13-1 50-3 59-13 5-6 8-26 8-59v-397h-87c-23 0-37 3-44 9-11 10-10 51-10 67h-54v-112z"
        />
        <path
          d="M9937 2741h37l202 437c18 36 29 57 34 62 8 7 32 9 43 10v33h-224v-33c10-1 53-2 53-15 0-7-6-27-21-58l-27-58h-226l-27 58c-13 29-20 48-20 59 0 15 32 13 44 14v33h-144v-33c12-1 36-2 43-10 5-5 16-26 32-63l200-437zm-17 134l-95 209h193l-98-209z"
        />
        <path
          d="M10641 2756v109h-54c-1-18-3-57-15-70-18-18-41-27-68-27-48 0-83 39-83 86 0 60 67 86 112 112 69 40 138 80 138 169 0 49-18 88-55 118-37 29-86 44-148 44-44 0-94-7-151-20v-112h54c1 20 3 56 19 70 22 18 48 27 78 27 57 0 96-38 96-96 0-70-58-90-112-119-90-49-135-104-135-162 0-46 16-83 49-110 32-28 76-42 129-42 42 0 90 8 145 24z"
        />
        <path
          d="M10950 2746h211v33c-10 0-50-1-50 13 0 11 4 31 12 59l70 249 111-348h41l119 369 86-269c2-6 5-17 9-31 4-15 6-24 6-28 0-17-34-15-46-15v-33h142v33c-8 0-15 1-23 1-13 0-20 2-23 7-3 4-11 26-23 66l-140 437h-35l-121-373-119 373h-37l-124-439c-11-36-19-57-23-62-7-8-32-10-43-10v-33z"
        />
        <path
          d="M11720 2746h228v33c-13 1-44 3-51 13-5 7-7 26-7 58v328c0 32 2 52 7 59 8 11 38 12 51 13v33h-228v-33c13-1 43-2 51-13 5-7 7-26 7-59v-328c0-32-2-52-7-58-8-10-38-12-51-13v-33z"
        />
        <path
          d="M12023 2746h281c84-1 148 22 194 67 45 45 67 109 67 192 0 85-23 153-69 204s-107 76-184 76h-289v-33c13-1 43-2 51-13 5-7 7-26 7-59v-328c0-32-2-52-7-58-8-10-38-12-51-13v-33zm170 36v397c0 30 4 48 13 56s31 11 65 11c58 0 102-20 131-61 29-40 44-101 44-182 0-80-16-138-48-171-32-34-87-51-164-51h-41z"
        />
        <path
          d="M12610 2746h233v33c-10 0-48-1-49 12 0 8 10 29 31 63l92 150 94-149c21-33 31-54 31-62 0-15-32-14-43-14v-33h147v33c-9 0-39 2-43 7-3 3-18 25-45 68l-125 196v128c0 32 2 52 7 59 8 11 38 12 51 13v33h-228v-33c13-1 43-2 51-13 5-7 7-26 7-59v-128l-122-197c-15-24-24-38-26-42-8-16-14-24-18-27-7-4-34-5-44-6v-33z"
        />
        <path
          d="M13330 2741h37l202 437c18 36 29 57 34 62 8 7 32 9 43 10v33h-224v-33c10-1 53-2 53-15 0-7-6-27-21-58l-27-58h-226l-27 58c-13 29-20 48-20 59 0 15 32 13 44 14v33h-144v-33c12-1 36-2 43-10 5-5 16-26 32-63l200-437zm-17 134l-95 209h193l-98-209z"
        />
        <path
          d="M13585 2746h502v112h-54c-1-16 0-57-10-67-7-6-21-9-44-9h-87v397c2 36 4 57 8 61 6 7 47 10 59 11v33h-246v-33c13-1 50-3 59-13 5-6 8-26 8-59v-397h-87c-23 0-37 3-44 9-11 10-10 51-10 67h-54v-112z"
        />
        <path
          d="M14286 2741h37l202 437c18 36 29 57 34 62 8 7 32 9 43 10v33h-224v-33c10-1 53-2 53-15 0-7-6-27-21-58l-27-58h-226l-27 58c-13 29-20 48-20 59 0 15 32 13 44 14v33h-144v-33c12-1 36-2 43-10 5-5 16-26 32-63l200-437zm-17 134l-95 209h193l-98-209z"
        />
        <path
          d="M14676 2746h180l148 348 152-348h162v33c-13 1-44 3-51 13-5 7-7 26-7 58v328c0 32 2 52 7 59 8 11 38 12 51 13v33h-211v-33c12-1 37-2 43-12 4-7 6-27 6-59v-320l-167 364h-37l-164-387v342c0 32 2 52 6 59 7 11 31 12 43 13v33h-161v-33c13-1 43-2 51-13 5-7 7-26 7-59v-328c0-32-2-52-7-58-8-10-38-12-51-13v-33z"
        />
        <path
          d="M15667 2741h37l202 437c18 36 29 57 34 62 8 7 32 9 43 10v33h-224v-33c10-1 53-2 53-15 0-7-6-27-21-58l-27-58h-226l-27 58c-13 29-20 48-20 59 0 15 32 13 44 14v33h-144v-33c12-1 36-2 43-10 5-5 16-26 32-63l200-437zm-17 134l-95 209h193l-98-209z"
        />
      </g>
      <g id="logoMain">
        <path
          d="M5361 116v1280c0 760 399 1105 1124 1110 835 5 1129-401 1129-1139V603h451v1856h559V603h299c182 0 458-83 458-487H7056v1438c0 332-310 478-599 469-539-16-525-451-525-880V602c0-368-204-487-571-487z"
        />
        <path
          d="M8822 1288h541c0-353 446-304 593-251 110 40 224 190 2 288-336 149-1287-37-1289 614-1 333 250 572 732 566 292-4 450-46 700-258 0 96-1 133 0 212h577c-42-110-92-69-91-450 1-241 30-849-48-1034-100-237-449-324-775-329-443-6-943 104-943 641zm1236 353c30-11 49-4 46 48-16 284-282 447-525 447-125 0-324 0-324-216 0-156 196-186 343-198 205-16 450-76 461-80z"
        />
        <path
          d="M10732 711v1748h505v-938c0-218 189-420 480-420 230 0 349 144 349 420v938h505v-938c0-95 70-418 442-418 297 0 369 178 369 418v938h505V1252c0-389-293-583-693-583-455 0-591 257-673 352-131-232-309-360-629-358-295 2-460 95-655 308V711h-505z"
        />
        <path
          d="M14120 1288h541c0-353 446-304 593-251 110 40 224 190 2 288-336 149-1287-37-1289 614-1 333 250 572 732 566 292-4 450-46 700-258 0 96-1 133 0 212h577c-42-110-92-69-91-450 1-241 30-849-48-1034-100-237-449-324-775-329-443-6-943 104-943 641zm1236 353c30-11 49-4 46 48-16 284-282 447-525 447-125 0-324 0-324-216 0-156 196-186 343-198 205-16 450-76 461-80z"
        />
      </g>
    </svg>
    <div id="shadow" class="op0"></div>
  </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";

export default {
  methods: {
    animate() {
      const logoUTama = "#logoUTama";
      const d0 = 1600;
      const d2 = 1000;
      const eoe = "easeOutExpo";
      const linear = "linear";
      const UwCon = "#UwCon";
      const logoMain = "#logoMain";
      const logoMainPath = logoMain + " path";
      const clone = document.getElementById("logoMain").cloneNode(true);
      clone.id = "logoMainC";
      document.getElementById("logoUTama").prepend(clone);
      const options = {
        loop: true,
        easing: "easeInOutSine",
        duration: d0,
      };
      anime
        .timeline(options)
        .add({
          targets: logoUTama,
          translateY: ["3vw", 0],
          easing: "easeOutQuad",
          duration: 3000,
        })
        .add(
          {
            targets: logoMainPath,
            strokeDashoffset: [anime.setDashoffset, 0],
            delay: (el, i) => i * 400,
          },
          0
        )
        .add(
          {
            targets: logoMain,
            strokeWidth: 0,
            easing: linear,
          },
          "-=800"
        )
        .add(
          {
            targets: "#logoMainC, #shadow",
            opacity: 1,
          },
          2000
        )
        .add(
          {
            targets: "#logoText path",
            translateX: [900, 0],
            opacity: [0, 1],
            easing: eoe,
            delay: (el, i) => 50 * i,
          },
          d2
        )
        .add(
          {
            targets: "#logoLine",
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 800,
          },
          1800
        )
        .add(
          {
            targets: "#logoN",
            strokeDashoffset: [anime.setDashoffset, 0],
          },
          d2
        )
        .add({
          targets: UwCon,
          scale: 3,
          opacity: {
            value: 0,
            duration: 600,
          },
          duration: 800,
          easing: "easeInExpo", //'easeInQuint',
          delay: d2,
        });
    },
  },
  mounted() {
    this.animate();
  },
};
</script>

<style lang="scss">
$col1: #28156e;
$col2: #e57716;
#UwCon {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.l-clip {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 22.926;
}
#logoN {
  fill: none;
  stroke: $col1;
  stroke-width: 339;
}
#logoLine {
  fill: none;
  stroke: $col2;
  stroke-width: 381;
}
#logoMain {
  fill: none;
  stroke-width: 30;
  stroke: $col1;
  stroke-linecap: round;
}
#logoText {
  path {
    opacity: 0;
    fill: $col2;
  }
}
#logoMainC {
  opacity: 0;
  fill: $col1;
}
#shadow {
  margin: 0 auto;
  background: rgba(black, 0.25);
  width: 90%;
  height: 0.6rem;
  filter: blur(0.8rem);
  border-radius: 50%;
  margin-top: 1.6rem;
  transform: translateZ(0);
}
</style>
