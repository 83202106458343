<template>
  <v-row>
    <v-col cols="12">
      <v-card class="depth-1">
        <v-card-title>Pertemuan {{ mtr.id }}</v-card-title>
        <v-card-subtitle class="pb-0"> {{ mtr.kulon }} </v-card-subtitle>
        <v-card-actions>
          <v-btn :color="appThemeColor + ' lighten-2'" text @click="expand">
            {{ mtr.text }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="expand">
            <v-icon>{{
              mtr.show ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="mtr.show">
            <v-divider></v-divider>
            <v-list-item v-for="item in mtr.kuliah" :key="item.id">
              <v-list-item-content>
                <v-list-item-title class="mb-1">{{
                  item.judul
                }}</v-list-item-title>
                <v-list-item-subtitle
                  v-for="(bhs, i) in item.bahasan"
                  :key="i"
                  v-html="bhs"
                  class="bhs"
                  style="white-space: normal"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AboutCards",
  props: {
    appThemeColor: String,
    materi: Object,
  },
  data: () => ({
    mtr: {},
  }),
  watch: {
    mtr: {
      handler(val) {
        this.mtr.text = val.show ? "Sembunyikan" : "Tampilkan";
      },
      deep: true,
    },
  },
  methods: {
    expand() {
      this.mtr.show = !this.mtr.show;
    },
  },
  mounted() {
    this.mtr = { ...this.materi };
    setTimeout(() => {
      this.mtr.show = true;
    }, 400);
  },
};
</script>

<style lang="scss">
.v-list-item .v-list-item__subtitle.bhs {
  line-height: 1.4;
  code {
    background: #999;
    color: #fff;
  }
}
</style>
