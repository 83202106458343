<template>
  <v-row>
    <v-col cols="12" lg="7">
      <AhoForm
        :mTop="mTop"
        :appThemeColor="appThemeColor"
        subtitle="Data akan disimpan di vuex store"
        @simpanMhs="simpanMhs"
        ref="form"
        list="Mahasiswa"
      />
    </v-col>
    <v-col cols="12" lg="5">
      <AhoSimpleTable :mTop="mTop" :appThemeColor="appThemeColor" />
    </v-col>
    <v-col cols="12">
      <AhoDataTable
        :mTop="mTop"
        :appThemeColor="appThemeColor"
        list="Mahasiswa"
        @focusInput="focusInput"
        @deleteCnf="deleteCnf"
      >
        <template>
          <v-toolbar-title> Data yang tersimpan di store </v-toolbar-title>
        </template>
      </AhoDataTable>
    </v-col>
    <AhoDialog
      :btnCol="[`${appThemeColor}`, 'error']"
      :modelValue="dialog"
      @update:modelValue="(newValue) => (dialog = newValue)"
      @batal="dialog = false"
      @lanjut="deleteMahasiswa(deleteConfirm)"
    >
      <template #title>Hapus data</template>
      <template>
        Apakah anda yakin ingin menghapus NPM: <kbd>{{ deleteConfirm }}</kbd> ?
      </template>
    </AhoDialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import AhoForm from "@/components/AhoForm.vue";
import AhoSimpleTable from "@/components/AhoSimpleTable.vue";
import AhoDataTable from "@/components/AhoDataTable.vue";
import AhoDialog from "@/components/AhoDialog.vue";

export default {
  name: "FormMahasiswa",
  components: {
    AhoForm,
    AhoSimpleTable,
    AhoDataTable,
    AhoDialog,
  },
  props: ["appThemeColor"],
  data: () => ({
    mTop: 4,
    dialog: false,
    deleteConfirm: null,
  }),
  computed: {
    ...mapGetters({
      labels: "getLabels",
      headers: "getHeaders",
      mhs: "getMahasiswa",
      listMhs: "getListMahasiswa",
      ipkE1: "getIpkE1",
      ipkE2: "getIpkE2",
      editing: "getEditing",
    }),
  },
  watch: {
    dialog: function (val) {
      if (!val && this.deleteConfirm != null) {
        this.deleteConfirm = null;
      }
    },
  },
  methods: {
    focusInput(n) {
      this.$refs.form.$refs.input[n].focus();
    },
    bukaSnackBar(txt, input, color) {
      this.$store.commit("setMessage", [txt, color]);
      if (input >= 0) {
        this.focusInput(input);
      }
    },
    simpanMhs() {
      let itr = 0;
      for (const [key, value] of Object.entries(this.mhs)) {
        if (value == "" || value == null) {
          this.bukaSnackBar(`${this.labels[key]} tidak boleh kosong`, itr, 1);
          return;
        }
        if (key == "ipk") {
          if (this.ipkE1) {
            this.bukaSnackBar(
              `${this.labels[key]} harus diantara 0 - 4`,
              itr,
              1
            );
            return;
          }
          if (this.ipkE2) {
            this.bukaSnackBar(`${this.labels[key]} harus berupa angka`, itr, 1);
            return;
          }
        }
        itr++;
      }
      this.mhs.ipk = parseFloat(this.mhs.ipk).toFixed(1);
      this.$store.commit("putMahasiswa", this.mhs);
      this.bukaSnackBar(
        `${this.mhs.npm} berhasil di${this.editing ? "ubah" : "tambah"}`,
        -1,
        0
      );
      this.$store.commit("clearMahasiswa");
    },
    deleteMahasiswa(npm) {
      this.$store.commit("deleteMahasiswa", npm);
      this.bukaSnackBar(`${npm} berhasil dihapus`, -1, 0);
      this.dialog = false;
      this.deleteConfirm = null;
    },
    deleteCnf(npm) {
      this.deleteConfirm = npm;
      this.dialog = true;
    },
  },
  beforeCreate() {
    const localMahasiswa = localStorage.getItem("listMahasiswa");
    if (localMahasiswa) {
      this.$store.commit("setListMahasiswa", JSON.parse(localMahasiswa));
    } else {
      localStorage.setItem(
        "listMahasiswa",
        JSON.stringify(this.$store.state.listMahasiswa)
      );
    }
  },
  // mounted() {
  //   this.focusInput(0);
  // },
};
</script>
