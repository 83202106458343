import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiServer: "", // ada di file: .env
    labels: {
      npm: "NPM",
      nama: "Nama",
      alamat: "Alamat",
      ipk: "IPK",
    },
    headers: [
      {
        text: "NPM",
        align: "start",
        value: "npm",
      },
      { text: "Nama", value: "nama" },
      { text: "Alamat", value: "alamat" },
      { text: "IPK", value: "ipk" },
      { text: "Actions", value: "actions", sortable: false, width: "0" },
    ],
    mahasiswa: {
      // temporary data untuk input form mahasiswa
      npm: "",
      nama: "",
      alamat: "",
      ipk: "",
    },
    listMahasiswa: [
      // data list mahasiswa di store
      {
        npm: "0620204011",
        nama: "Mohamad Ramdan",
        alamat: "Sample store 1",
        ipk: "3.8",
      },
      {
        npm: "0620204077",
        nama: "Sakura",
        alamat: "Sample store 2",
        ipk: "4.0",
      },
      {
        npm: "0620204066",
        nama: "Nobita",
        alamat: "Sample store 3",
        ipk: "3.9",
      },
    ],
    listMhs: [], // data list mahasiswa dari backend
    message: [], // message untuk snackbar, msg: 0 = success; 1 = error; 2 = warning; 3 = info
    ipkE1: false,
    ipkE2: false,
    editing: false,
    editNpm: null,
  },
  mutations: {
    setServer(state, server) {
      state.apiServer = server;
    },
    setMahasiswa(state, mhs) {
      // set data mahasiswa di store
      state.mahasiswa = mhs;
    },
    clearMahasiswa(state) {
      // clear data mahasiswa di store
      state.mahasiswa = {
        npm: "",
        nama: "",
        alamat: "",
        ipk: "",
      };
    },
    setListMahasiswa(state, payload) {
      // set data mahasiswa dari local storage
      state.listMahasiswa = payload;
    },
    putMahasiswa(state, mhs) {
      const adaDiIndex = state.listMahasiswa.findIndex(
        (i) => i.npm === mhs.npm
      );
      if (adaDiIndex !== -1) {
        // untuk handle edit data
        state.listMahasiswa[adaDiIndex] = mhs;
        state.listMahasiswa = [...state.listMahasiswa]; // refresh listMahasiswa
      } else {
        // untuk handle tambah data
        state.listMahasiswa.push(mhs);
      }
      setLocalListMahasiswa(state);
    },
    deleteMahasiswa(state, npm) {
      // delete data mahasiswa di store
      const adaDiIndex = state.listMahasiswa.findIndex((i) => i.npm === npm);
      if (adaDiIndex !== -1) {
        state.listMahasiswa.splice(adaDiIndex, 1);
        if (npm == state.mahasiswa.npm) {
          state.mahasiswa = {
            npm: "",
            nama: "",
            alamat: "",
            ipk: "",
          };
        }
      }
      setLocalListMahasiswa(state);
    },
    setListMhs(state, payload) {
      // set data list mahasiswa dari backend
      state.listMhs = payload;
      state.message = ["Get data berhasil", 0];
    },
    putListMhs(state, mhs) {
      let msg = "";
      const adaDiIndex = state.listMhs.findIndex((i) => i.npm === mhs.npm);
      if (adaDiIndex !== -1) {
        // untuk handle edit data
        state.listMhs[adaDiIndex] = mhs;
        state.listMhs = [...state.listMhs]; // refresh listMhs
        msg = "ubah";
      } else {
        // untuk handle tambah data
        state.listMhs.push(mhs);
        msg = "tambah";
      }
      state.message = [`${mhs.npm} berhasil di${msg}`, 0];
    },
    deleteMhs(state, npm) {
      // menghapus data list mahasiswa dari backend
      const adaDiIndex = state.listMhs.findIndex((i) => i.npm === npm);
      if (adaDiIndex !== -1) {
        state.listMhs.splice(adaDiIndex, 1);
        state.message = [`${npm} berhasil dihapus`, 0];
      }
    },
    setMessage(state, msg) {
      // set error code dari backend
      state.message = msg;
    },
    setIpkE1(state, bool) {
      state.ipkE1 = bool;
    },
    setIpkE2(state, bool) {
      state.ipkE2 = bool;
    },
    setEditing(state, bool) {
      state.editing = bool;
    },
    setEditNpm(state, npm) {
      state.editNpm = npm;
    },
  },
  actions: {
    async getDataMhs({ commit }) {
      // load data mahasiswa dari backend
      try {
        const response = await axios.get(`${this.state.apiServer}/mhsw`);
        commit("setListMhs", response.data);
        logErr("GET", response);
      } catch (error) {
        commit("setMessage", [error.response.data || error.message, 1]);
        logErr("GET", error, true);
      }
    },
    async putMhs({ commit }, mhs) {
      // update atau tambah data mahasiswa di backend
      try {
        const response = await axios.put(`${this.state.apiServer}/mhsw`, mhs);
        commit("putListMhs", mhs);
        logErr("PUT", response);
      } catch (error) {
        commit("setMessage", [error.response.data || error.message, 1]);
        logErr("PUT", error, true);
      }
    },
    async deleteMhs({ commit }, npm) {
      // hapus data mahasiswa dari backend
      try {
        const response = await axios.delete(
          `${this.state.apiServer}/mhsw/${npm}`
        );
        commit("deleteMhs", npm);
        logErr(`DELETE:${npm}`, response);
      } catch (error) {
        commit("setMessage", [error.response.data || error.message, 1]);
        logErr(`DELETE:${npm}`, error, true);
      }
    },
  },
  getters: {
    getLabels: (state) => state.labels,
    getHeaders: (state) => state.headers,
    getMahasiswa: (state) => state.mahasiswa, // untuk get data mahasiswa di store
    getListMahasiswa: (state) => state.listMahasiswa, // untuk get data list mahasiswa di store
    getListMhs: (state) => state.listMhs, // untuk get data list mahasiswa dari backend
    getMessage: (state) => state.message, // untuk get error code dari backend
    getIpkE1: (state) => state.ipkE1,
    getIpkE2: (state) => state.ipkE2,
    getEditing: (state) => state.editing,
    getEditNpm: (state) => state.editNpm,
  },
  modules: {},
});

function logErr(method, msg, err = false) {
  console.log({
    method: method,
    status: err ? msg.response.status || msg.code : msg.status,
    statusText: err ? msg.response.statusText || msg.name : msg.statusText,
    message: err ? msg.response.data || msg.message : msg.data,
  });
}

function setLocalListMahasiswa(store) {
  // simpan data list mahasiswa ke local storage
  localStorage.setItem("listMahasiswa", JSON.stringify(store.listMahasiswa));
}
