<template>
  <section
    :id="$route.name"
    class="transition-swing"
    :class="$vuetify.breakpoint.xsOnly ? 'mx-n4' : ''"
  >
    <FormMahasiswa :appThemeColor="appThemeColor" />
  </section>
</template>

<script>
import FormMahasiswa from "../components/FormMahasiswa.vue";

export default {
  components: {
    FormMahasiswa,
  },
  props: ["appThemeColor"],
  data: () => ({}),
};
</script>
