<template>
  <v-hover v-slot="{ hover }">
    <AhoCard1 :mTop="mTop">
      <template #default>
        <v-toolbar
          :color="appThemeColor"
          dark
          class="aho-tr1 depth-2 rounded"
          :class="'mt-' + (hover ? 'n' + mTop * 1.5 : 'n' + mTop)"
          style="position: absolute; width: 100%"
        >
          <slot />
        </v-toolbar>
      </template>
      <template #a>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="listMhs"
          :items-per-page="10"
          dense
          :mobile-breakpoint="0"
          :loading="loading"
          loading-text="Mengambil dari REST Server..."
          no-data-text="Belum ada data"
          v-model="selected"
          item-key="npm"
          single-select
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editMhs(item.npm)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteCnf(item.npm)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </template>
    </AhoCard1>
  </v-hover>
</template>

<script>
import { mapGetters } from "vuex";
import AhoCard1 from "@/components/AhoCard1.vue";

export default {
  name: "AhoDataTable",
  components: {
    AhoCard1,
  },
  props: ["mTop", "appThemeColor", "list", "loading"],
  data: () => ({
    selected: [],
  }),
  computed: {
    ...mapGetters({
      headers: "getHeaders",
      listA: "getListMhs",
      listB: "getListMahasiswa",
      editNpm: "getEditNpm",
    }),
    listMhs() {
      return this.list == "Mhs" ? this.listA : this.listB;
    },
  },
  watch: {
    editNpm: function (val) {
      if (val) {
        this.selected = [{ npm: val }];
      } else {
        this.selected = [];
      }
    },
  },
  methods: {
    editMhs(npm) {
      const m = this.listMhs.find((item) => {
        return item.npm == npm;
      });
      const mhs = {
        npm: m.npm,
        nama: m.nama,
        alamat: m.alamat,
        ipk: m.ipk,
      };
      this.$store.commit("setMahasiswa", mhs);
      this.$emit("focusInput", 1);
    },
    deleteCnf(npm) {
      this.$emit("deleteCnf", npm);
    },
  },
};
</script>
