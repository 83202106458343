import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#483E9C",
        secondary: "#BA9E66",
        // accent: colors.shades.black,
        // error: colors.red.accent3,
        background: "#f5f5f5",
      },
      dark: {
        primary: "#483E9C",
        secondary: "#BA9E66",
        background: "#222",
      },
    },
  },
});
