<template>
  <v-card
    :class="
      'mt-' +
      mTop +
      ' ' +
      ($vuetify.breakpoint.xsOnly ? 'rounded-0 elevation-0' : 'depth-1')
    "
  >
    <v-card-text class="py-0">
      <div style="position: relative">
        <slot />
      </div>
    </v-card-text>
    <v-card-title> &nbsp; </v-card-title>
    <slot name="a" />
  </v-card>
</template>

<script>
export default {
  name: "AhoCard1",
  props: ["mTop"],
};
</script>
