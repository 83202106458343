<template>
  <section
    :id="$route.name"
    class="white pa-4 transition-swing text-justify"
    :class="$vuetify.breakpoint.xsOnly ? 'mx-n4' : 'rounded-lg depth-1'"
  >
    <v-row>
      <v-col cols="12">
        <p>
          Website SPA atau Single Page Aplication ini dibuat untuk memenuhi
          tugas mata kuliah Pengembangan Aplikasi Berbasis Web pada semester 3,
          Program Studi Teknik Informatika, Universitas Widyatama.
        </p>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <LogoUTama />
      </v-col>
      <v-col cols="12">
        <AhoH2>Data Diri Mahasiswa</AhoH2>
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 12 : 2"
            class="mx-auto transition-swing mb-6"
            width="360"
          >
            <v-img height="200px" :src="require('../assets/header-utama.jpg')">
              <v-card-title class="white--text fill-height align-end pa-0">
                <div
                  class="d-flex align-center pa-4"
                  style="background: rgba(0, 0, 0, 0.5); width: 100%"
                >
                  <v-avatar size="56">
                    <img alt="user" :src="require('../assets/pas.jpg')" />
                  </v-avatar>
                  <p class="ml-3 my-0 text-truncate">{{ authorName }}</p>
                </div>
              </v-card-title>
            </v-img>
            <v-card-text>
              <div class="font-weight-bold ml-6 mb-2">UTama</div>
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(item, i) in items"
                  :key="i"
                  :color="appThemeColor"
                  small
                >
                  <div>
                    <div class="font-weight-normal">
                      <strong>{{ item.key }}</strong>
                    </div>
                    <div v-html="item.item"></div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-hover>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <AhoH2>Development Environment</AhoH2>
        <h3 class="text-h5 mb-4 font-weight-light">Vue CLI</h3>
        <p>
          Vue CLI adalah sebuah tool yang dapat digunakan untuk membuat aplikasi
          web dengan framework Vue. Berikut ini adalah step by step untuk
          menjalankan development environment dengan menggunakan Vue CLI:
        </p>
        <ol>
          <li>Pastikan di komputer sudah terinstall Node.js dan npm.</li>
          <li>
            Buat project baru dengan perintah
            <kbd>vue create nama-project</kbd>.
          </li>
          <li>
            Pilih feature yang akan digunakan, seperti vuex, eslint, router dan
            lain-lain sesuai dengan kebutuhan.
          </li>
          <li>
            Setelah project terbuat, buka folder project tersebut dengan
            perintah <kbd>cd nama-project</kbd>.
          </li>
          <li>
            Untuk menjalankan development environment, gunakan perintah
            <kbd>npm run serve</kbd>.
          </li>
          <li>
            Maka aplikasi akan dapat diakses melalui web browser dengan URL
            <kbd>http://localhost:8080</kbd>.
            <v-alert
              border="left"
              type="info"
              text
              :color="appThemeColor"
              class="depth-1 mt-1"
            >
              Port default yang biasanya digunakan adalah port
              <kbd>8080</kbd>, tapi apabila port tersebut sudah digunakan oleh
              aplikasi lain, maka port yang digunakan akan berubah menjadi port
              berikutnya yang masih available. Biasanya port yang berikutnya
              adalah port <kbd>8081</kbd>.
            </v-alert>
          </li>
        </ol>
        <h3 class="text-h5 mb-4 font-weight-light">Expres.js</h3>
        <p>
          Express.js adalah sebuah framework untuk menjalankan aplikasi web
          dengan menggunakan Node.js. Tapi pada kesempatan kali ini kita akan
          menggunakan Express.js sebagai server REST API yang akan dipanggil
          dari vue melalui axios.
        </p>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <AhoH2> Build Test dan Upload ke Server </AhoH2>
        <p>
          Aplikasi web ini dapat di-build dengan menggunakan perintah
          <kbd>npm run build</kbd>.
        </p>
        <p>
          Apabila aplikasi ini di-upload ke hosting yang tidak support Node.js,
          maka akses ke backend menjadi tidak memungkinkan. Solusinya adalah
          dengan membuat REST API server dengan menggunakan PHP.
        </p>
        <p>
          masalah selanjutnya yang muncul adalah karena penggunan vue router,
          sehingga apabila landing page langsung ke halaman selain home maka
          akan menghasilkan error 404 not found. Untuk mengatasi masalah ini
          bisa dengan menambahkan redirect ke index.html melalui file
          <code>.htaccess</code>.
        </p>
        <p>Berikut isi dari file <code>.htaccess</code>:</p>
        <v-alert border="left" dark color="black" class="mb-0" id="readMeAlert">
          <pre class="pl-2 language-apache">
RewriteEngine On
RewriteBase /
RewriteRule ^index\.html$ - [L]
RewriteCond %{REQUEST_FILENAME} !-f
RewriteCond %{REQUEST_FILENAME} !-d
RewriteRule . /index.html [L] </pre
          >
        </v-alert>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import AhoH2 from "@/components/AhoH2.vue";
import LogoUTama from "@/components/LogoUTama.vue";

export default {
  components: {
    AhoH2,
    LogoUTama,
  },
  props: ["appThemeColor"],
  data: () => ({
    authorName: process.env.VUE_APP_AUTHOR_NAME,
    website: "ahonerd.com",
  }),
  computed: {
    items() {
      return [
        {
          key: "NPM",
          item: "0620204011",
        },
        {
          key: "Alamat",
          item: "Cimahi, Jawa Barat",
        },
        {
          key: "Website",
          item: `<a href="https://${this.website}" target="_blank" class="text-decoration-none ${this.appThemeColor}--text">${this.website}</a>`,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
#readMeAlert .v-alert__content {
  overflow-x: auto;
}
#Home {
  p,
  li {
    font-size: 1.25rem;
    font-weight: 300;
  }
}
</style>
