<template>
  <v-hover v-slot="{ hover }">
    <AhoCard1 :mTop="mTop">
      <template #default>
        <v-card
          :color="
            ipkE1 || ipkE2 ? 'error' : editing ? 'warning' : appThemeColor
          "
          dark
          class="aho-tr1 depth-2 rounded"
          :class="'mt-' + (hover ? 'n' + mTop * 1.5 : 'n' + mTop)"
          style="position: absolute; width: 100%"
        >
          <v-card-title>
            {{
              ipkE1
                ? "IPK harus diantara 0 - 4"
                : ipkE2
                ? "IPK harus berupa angka"
                : "Data Mahasiswa"
            }}
          </v-card-title>
          <v-card-subtitle> Computed dari mapGetters </v-card-subtitle>
        </v-card>
      </template>
      <template #img>
        <v-img :src="require('../assets/header-utama.jpg')"></v-img>
      </template>
      <template #a>
        <v-card-subtitle> &nbsp; </v-card-subtitle>
        <v-card-text class="d-flex">
          <QrcodeVue
            :value="mhs.npm || 'NPM'"
            render-as="svg"
            size="80"
            class="qr"
          />
          <div class="ml-4">
            <h2 class="text-h4 mb-2 text-capitalize">
              {{ mhs.nama || "Nama" }}
            </h2>
            <div
              class="text-h5"
              :class="
                (ipkE1 || ipkE2
                  ? 'error'
                  : editing
                  ? 'warning'
                  : appThemeColor) + '--text'
              "
            >
              {{ mhs.npm || "NPM" }}
            </div>
          </div>
        </v-card-text>
        <v-card-text class="pt-0" style="position: relative">
          <v-divider></v-divider>
          <v-btn
            fab
            dark
            large
            absolute
            right
            top
            :color="
              ipkE1 || ipkE2 ? 'error' : editing ? 'warning' : appThemeColor
            "
            class="text-h6 text-truncate"
          >
            {{ mhs.ipk || "IPK" }}
          </v-btn>
          <div class="pt-3 text-h5 text-capitalize">
            {{ mhs.alamat || "Alamat" }}
          </div>
        </v-card-text>
      </template>
    </AhoCard1>
  </v-hover>
</template>

<script>
import { mapGetters } from "vuex";
import AhoCard1 from "@/components/AhoCard1.vue";
import QrcodeVue from "qrcode.vue";

export default {
  name: "AhoSimpleTable",
  components: {
    AhoCard1,
    QrcodeVue,
  },
  props: ["mTop", "appThemeColor"],
  computed: {
    ...mapGetters({
      labels: "getLabels",
      mhs: "getMahasiswa",
      ipkE1: "getIpkE1",
      ipkE2: "getIpkE2",
      editing: "getEditing",
    }),
  },
};
</script>

<style>
.qr svg {
  display: block;
}
</style>
