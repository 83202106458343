import Vue from "vue";
import VueRouter from "vue-router";
import anime from "animejs/lib/anime.es.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    icon: "mdi-book-open-variant",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/ReadMe.vue"),
    meta: {
      title: "Home",
      description: "Selamat datang di homepage aplikasi Data Mahasiswa",
    },
  },
  {
    path: "/mhs",
    name: "Mhs",
    icon: "mdi-server-network",
    component: () =>
      import(/* webpackChunkName: "mhs" */ "../views/MahasiswaExpress.vue"),
    meta: {
      title: "Data Mahasiswa",
      description:
        "Data yang ditampilkan di halaman ini diolah menggunakan vuex dan disimpan di REST server melalui axios",
    },
  },
  {
    path: "/mahasiswa",
    name: "Mahasiswa",
    icon: "mdi-account-school",
    component: () =>
      import(/* webpackChunkName: "mahasiswa" */ "../views/MahasiswaView.vue"),
    meta: {
      title: "Data Mahasiswa (store)",
      description:
        "Data yang ditampilkan di halaman ini diolah menggunakan vuex dan disimpan di vuex store. Data syncronized dengan localStorage, sehingga data tidak hilang saat refresh browser",
    },
  },
  {
    path: "/about",
    name: "About",
    icon: "mdi-book-information-variant",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "About",
      description:
        "Halaman ini berisi materi yang sudah dipelajari dari mata kuliah Pengembangan Aplikasi Berbasis Web",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(["beforeEach", to, from]);
  document.title = to.meta.title + " - " + process.env.VUE_APP_AUTHOR_NPM;
  const pageTitle = document.getElementById("pageTitle");
  const rvWrap = document.getElementById("rvWrap");
  if (pageTitle) {
    // supaya tidak trigger saat pertama kali akses halaman
    // console.log(pageTitle.innerText);
    pageTitle.innerHTML = brtxt(from.meta.title, "l");
    const options = {
      easing: "easeOutQuad",
      duration: 200,
    };
    anime
      .timeline(options)
      .add({
        targets: pageTitle.querySelectorAll(".l"),
        opacity: 0,
        scale: 0,
        delay: anime.stagger(25, { from: "last" }),
        complete: () => {
          pageTitle.innerHTML = brtxt(to.meta.title, "l op0");
          const child = pageTitle.querySelectorAll(".l");
          anime.timeline(options).add({
            targets: child,
            opacity: 1,
            scale: 1,
            delay: anime.stagger(60, { start: 200 }),
            complete: () => {
              child.forEach((el) => {
                el.classList.remove("op0");
                el.removeAttribute("style", "");
              });
            },
          });
        },
      })
      .add(
        {
          targets: rvWrap,
          opacity: 0,
          duration: 400,
          complete: () => {
            next();
            anime.timeline(options).add({
              targets: rvWrap,
              opacity: 1,
              translateY: [16, 0],
              duration: 600,
              complete: () => {
                rvWrap.classList.remove("op0");
                rvWrap.removeAttribute("style", "");
              },
            });
          },
        },
        0
      );
  } else {
    next();
  }
});

function brtxt(txt, cls) {
  return txt.replace(/\S/g, `<span class="${cls}">$&</span>`);
}

export default router;
