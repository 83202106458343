<template>
  <v-dialog v-model="value" max-width="290">
    <v-card>
      <v-card-title class="text-h5"><slot name="title" /></v-card-title>
      <v-card-text><slot /></v-card-text>
      <v-card-text class="d-flex justify-end pt-0">
        <v-btn :color="btnCol[0]" dark @click="batal"> Batal </v-btn>
        <div class="pa-2"></div>
        <v-btn :color="btnCol[1]" dark @click="lanjut"> Hapus </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AhoDialog",
  props: ["modelValue", "btnCol"],
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    batal() {
      this.$emit("batal");
    },
    lanjut() {
      this.$emit("lanjut");
    },
  },
};
</script>
